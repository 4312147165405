// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import AOS from "aos"
import Bluebird from "bluebird"
import Bootstrap from "bootstrap"
import Cookieconsent from "cookieconsent"
import Countup from "countup.js"
import Imagesloaded from "imagesloaded"
import IsotopeLayout from "isotope-layout"
import Jarallax from "jarallax"
import Jquery from "jquery"
import NumberIsnan from "number.isnan"
import Photoswipe from "photoswipe"
import Popper from "popper.js"
import SmoothScroll from "smooth-scroll"
import StickyJs from "sticky-js"
import StringIncludesPolyfill from "string-includes-polyfill"
import Swiper from "swiper"
import TypedJs from "typed.js"

window.AOS = AOS
window.Bluebird = Bluebird
window.Bootstrap = Bootstrap
window.Cookieconsent = Cookieconsent
window.Countup = Countup
window.Imagesloaded = Imagesloaded
window.IsotopeLayout = IsotopeLayout
window.Jarallax = Jarallax
window.NumberIsnan = NumberIsnan
window.Photoswipe = Photoswipe
window.Popper = Popper
window.SmoothScroll = SmoothScroll
window.StickyJs = StickyJs
window.StringIncludesPolyfill = StringIncludesPolyfill
window.Swiper = Swiper
window.TypedJs = TypedJs
window.WhatwgFetch = WhatwgFetch
